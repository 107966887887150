import axios from "axios";

// List of all possible categories
export const allCategories = [
  "Community Gatherings",
  "Charity and Volunteering",
  "Sports and Recreation",
  "Professional Development",
  "Health and Wellness",
  "Advocacy and Awareness",
  "Youth",
  "Children",
  "Art and Culture",
  "Educational Programs",
  "Halaqas",
  "Lectures",
  "Conferences",
  "Seminars",
  "Workshops",
  "Quranic Activities",
  "Quran Recitation Sessions",
  "Quran Memorization Classes",
  "Tafsir (Quranic Exegesis) Sessions",
  "Religious Education",
  "Islamic Studies Classes",
  "Fiqh (Islamic Jurisprudence) Classes",
  "Seerah (Prophet's Biography) Classes",
  "Religious Celebrations",
  "Ramadan Events",
  "Eid Celebrations",
  "Brothers",
  "Sisters",
];

const validateAndFormatCategories = (categories) => {
  if (typeof categories === "string") {
    try {
      categories = JSON.parse(categories);
      categories = Object.values(categories).map((cat) => cat.trim());
    } catch (error) {
      console.error("Error parsing categories JSON string:", error);
      categories = [];
    }
  }

  if (!Array.isArray(categories)) {
    categories = [];
  }

  const formattedCategories = categories.map((category) => {
    if (allCategories.includes(category)) {
      return category;
    }
    return "Uncategorized";
  });

  console.log("Formatted categories:", formattedCategories);
  return formattedCategories;
};

export const fetchEvents = async (selectedOrganization, selectedCategories) => {
  try {
    const response = await axios.get("/api/v1/events");
    console.log("Raw events data:", response.data);

    const events = response.data.map((event) => {
      const sanitizedEvent = {
        ...event,
        categories: event["categories "] || event.categories || "[]",
      };
      const validatedCategories = validateAndFormatCategories(
        sanitizedEvent.categories
      );
      console.log("Event before formatting:", event);
      console.log("Validated categories:", validatedCategories);

      return {
        ...sanitizedEvent,
        categories: validatedCategories,
      };
    });

    console.log("Fetched events with categories:", events);
    return filterEvents(events, selectedOrganization, selectedCategories);
  } catch (error) {
    console.error("Error fetching events:", error);
    return [];
  }
};

export const filterEvents = (
  events,
  selectedOrganization,
  selectedCategories
) => {
  return events.filter((event) => {
    console.log("Selected organization:", selectedOrganization);
    console.log("Selected categories:", selectedCategories);

    const organizationMatch =
      !selectedOrganization || event.organization_id === selectedOrganization;

    const categoriesMatch =
      selectedCategories.length === 0 ||
      selectedCategories.some((category) =>
        event.categories.includes(category)
      );

    console.log("organizationMatch", organizationMatch);
    console.log("categoriesMatch", categoriesMatch);
    console.log("Event categories:", event.categories);

    return organizationMatch && categoriesMatch;
  });
};
