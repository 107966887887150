import React from "react";

const Donate = () => {
  return (
    <div>
      <p>Work in Progress...</p>
    </div>
  );
};

export default Donate;
